import { mode } from '@chakra-ui/theme-tools';

export const styles = {
    global: (props: any) => ({
        html: {
            height: "100%",
            margin: 0
        },
        body: {
            height: "100%",
            margin: 0,
            background: mode('white', '#121212')(props),
        },
        "#__next": {
            height: "100%"
        }
    })
};
