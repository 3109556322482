import { extendTheme } from '@chakra-ui/react';
import { styles } from "./styles"

// 2. Add your color mode config
const theme = {
    styles,
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    }
};


// 3. extend the theme
export default extendTheme(theme);


