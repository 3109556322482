import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import Script from 'next/script';

import theme from '../theme/theme';
import { Analytics } from '@vercel/analytics/react';

function AppWrapper({ Component, pageProps }: AppProps) {
    return (
        <ChakraProvider theme={theme}>
            <Component {...pageProps} />
            <Analytics />
        </ChakraProvider>
    );
}

export default AppWrapper;
